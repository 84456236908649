/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import { Header } from "../components"
import { useWindowHeightCssVariable } from "../services"
import { Global, css } from "@emotion/core"
import { Footer } from "../components/Footer"
import { SEO } from "../components/seo"
import { StaticStory } from "../components/StaticStory"

function IndexPage() {
    useWindowHeightCssVariable()

    return (
        <Box bg="sd.beige" color="black" as="main">
            <GlobalCSS />

            <SEO />

            <Header color="sd.black" />

            <StaticStory />

            <Footer />
        </Box>
    )
}

function GlobalCSS() {
    return (
        <Global
            styles={css`
                 {
                    body {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        background-color: #eae4e4;
                    }
                    html {
                        font-size: calc(1rem + 0.25vw);
                        background-color: #eae4e4;
                    }

                    .kraken {
                        text-align: center;
                        max-width: 20ch;
                        background-color: rgba(234, 228, 228, 0.8);
                    }

                    .kraken--hide {
                        display: none;
                    }

                    .kraken__headline {
                        font-family: "Heebo", sans-serif;
                        text-transform: uppercase;
                        font-weight: 900;
                        font-size: 0.8rem;
                    }

                    .kraken--special {
                        color: #3353ff;
                    }
                }
            `}
        />
    )
}

export default IndexPage
